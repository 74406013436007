let SimpleLightbox = require('simple-lightbox');

(function initGalleryLightbox() {
	const lightboxContainer = document.querySelectorAll('.gallery-lightbox');
	if (!lightboxContainer) return;
	lightboxContainer.forEach((item) => {
		const lightboxItems = item.querySelectorAll('.gallery-lightbox-item');
		new SimpleLightbox({
			elements: lightboxItems,
		});
	});
})();
