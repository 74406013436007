import HandyCollapse from 'handy-collapse';

const navMenu = document.querySelector('.nav'),
	navHelper = document.querySelector('.nav__helper');

function stickyMenu() {
	const navHandler = (entries) => {
		entries[0].isIntersecting ? navMenu.classList.remove('nav--scroll') : navMenu.classList.add('nav--scroll');
	};

	const observer = new IntersectionObserver(navHandler);
	observer.observe(navHelper);
}

const submenus = () => {
	new HandyCollapse({
		nameSpace: 'submenu',
		activeClass: 'is-active',
		isAnimation: true,
		closeOthers: true,
		animationSpeed: 600,
		cssEasing: 'ease',
	});
};

function initHamburger() {
	const hamburgerButton = document.querySelector('.nav__hamburger');

	hamburgerButton.addEventListener('click', () => {
		navMenu.classList.toggle('is-open');
	});
}

export const initMenu = () => {
	submenus();
	stickyMenu();
	initHamburger();
};
