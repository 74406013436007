import mapboxgl from 'mapbox-gl';

(function initContactMap() {
	const contactMap = document.querySelector('.contact-map');

	if (!contactMap) return;

	const contactMapInfo = document.querySelector('.map__contact-info');

	// Settings
	const API_KEY = 'pk.eyJ1IjoiZG96YWtvZHUiLCJhIjoiY2xmcW8zMnYxMDBkaDN5cW1kZWM1Zml5ZiJ9.nnfwL6pcy8ebVrgRJ80M3Q';
	const STYLE_URL = 'mapbox://styles/dozakodu/clc0uwozu000114qc85ymectj';

	mapboxgl.accessToken = API_KEY;
	const coordX = contactMap.dataset.coordX;
	const coordY = contactMap.dataset.coordY;

	let mapCenter = [coordY, coordX];

	const map = new mapboxgl.Map({
		container: contactMap,
		style: STYLE_URL,
		center: mapCenter,
		zoom: 7,
		cooperativeGestures: true,
	});

	addMarkers(map);

	function addMarkers(map) {
		const markerEl = document.createElement('div');
		markerEl.className = 'map-marker';

		new mapboxgl.Marker(markerEl).setLngLat(mapCenter).addTo(map);

		const mapHandler = (entries) => {
			if (entries[0].isIntersecting) {
				setTimeout(() => {
					map.flyTo({
						center: mapCenter,
						zoom: 13,
						duration: 5000,
						essential: true,
					});
				}, 500);
			}
		};

		const observer = new IntersectionObserver(mapHandler);
		observer.observe(contactMapInfo);
	}
})();
