import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper/core';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

(function materialsSlider() {
	new SwiperCore('.materials-slider', {
		spaceBetween: 30,
		effect: 'fade',
		loop: true,
		autoplay: {
			delay: 3000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		speed: 1000,
		pagination: {
			el: '.materials-header__pagination',
			clickable: true,
		},
	});
})();
