import tippy from 'tippy.js';
import SlimSelect from 'slim-select';

(function initContactForm() {
	const wpcf7Container = document.querySelector('.wpcf7-form');

	if (!wpcf7Container) return;

	const contactForm = wpcf7Container.closest('#contact-form');
	const sentMsg = contactForm?.querySelector('.sent-msg');
	const allInputs = document.querySelectorAll('.form__input');

	// Checkboxes
	tippy('.form__checkbox-tooltip', {
		animation: 'scale',
		maxWidth: 400,
		content(reference) {
			return reference.textContent;
		},
	});

	// Select
	const allSelects = document.querySelectorAll('.wpcf7-form .global-select');

	allSelects.forEach((select) => {
		new SlimSelect({
			select: select,
			showSearch: false,
			onChange: (info) => {
				select.classList.add('is-selected');
			},
		});
	});

	// Blocking checking checkbox when clicked tooltip
	const allTooltips = wpcf7Container.querySelectorAll('.form__checkbox-tooltip');

	allTooltips.forEach((tooltip) => {
		tooltip.addEventListener('click', (e) => {
			e.preventDefault();
		});
	});

	// Labels
	const focusLabels = (item) => {
		item.addEventListener('focusin', (e) => {
			e.target.closest('.form-details__group').classList.add('is-focus');
		});

		item.addEventListener('change', (e) => {
			e.target.closest('.form-details__group').classList.add('is-focus');
		});

		item.addEventListener('focusout', (e) => {
			if (e.target.value === '') {
				e.target.closest('.form-details__group').classList.remove('is-focus');
			}
		});
	};

	// Remove focus on submit
	const mailSent = (input) => {
		input.closest('.form-details__group').classList.remove('is-focus');
	};

	if (allInputs) {
		allInputs.forEach((input) => {
			focusLabels(input);
		});
	}

	if (wpcf7Container) {
		wpcf7Container.addEventListener(
			'wpcf7mailsent',
			(e) => {
				sentMsg.classList.add('is-send');

				setTimeout(() => {
					allInputs.forEach((input) => {
						mailSent(input);
					});
				}, 2000);

				setTimeout(() => {
					sentMsg.classList.remove('is-send');
				}, 8000);
			},
			false
		);
	}
})();
