let SimpleLightbox = require('simple-lightbox');

(function initLightbox() {
	const lightboxContainer = document.querySelectorAll('.lightbox');
	if (!lightboxContainer) return;
	lightboxContainer.forEach((item) => {
		const lightboxItems = item.querySelectorAll('.lightbox-item');
		new SimpleLightbox({
			elements: lightboxItems,
		});
	});
})();
