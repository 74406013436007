import SwiperCore, { Pagination, Autoplay, EffectFade } from 'swiper/core';

SwiperCore.use([Pagination, Autoplay, EffectFade]);

(function productSlider() {
	new SwiperCore('.products-slider', {
		effect: 'fade',
		loop: true,
		autoplay: {
			delay: 4000,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		fadeEffect: {
			crossFade: true,
		},
		speed: 1000,
		pagination: {
			el: '.product-slider__pagination',
			clickable: true,
		},
	});
})();
